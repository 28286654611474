import { initNFTFallEffect } from './snowEffect.js';

// pageWithEdit.js
export class EditSidebar {
  constructor(router) {
    console.log('EditSidebar constructor starting with router:', router);
    this.router = router;
    this.init();
    this.contentManager = new ContentBlockManager(); // Add this line
    this.inlineEditor = new InlineContentEditor(this);
    this.initializeSocialLinksToggle();
    this.initializeDarkModeToggle();
    this.initializeProfilePhotoToggle(); // Add this line

  }

  initializeSocialLinks(pageData) {
    const socialLinksToggle = document.getElementById('socialLinksEnabled');
    const socialLinksFields = document.getElementById('socialLinksFields');

    // Check if we have social links data
    const socialLinks = pageData?.socialLinks?.links || {};
    const hasSocialLinks = Object.values(socialLinks).some(link => link);

    if (socialLinksToggle && socialLinksFields) {
      // Set toggle state based on existing data
      socialLinksToggle.checked = hasSocialLinks || pageData?.socialLinks?.enabled;

      // Show/hide fields based on toggle state
      socialLinksFields.classList.toggle('hidden', !socialLinksToggle.checked);

      // Set existing values if they exist
      if (hasSocialLinks) {
        if (socialLinks.x) {
          document.getElementById('xUrl').value = socialLinks.x;
        }
        if (socialLinks.discord) {
          document.getElementById('discordUrl').value = socialLinks.discord;
        }
        if (socialLinks.website) {
          document.getElementById('websiteUrl').value = socialLinks.website;
        }
      }

      // Add toggle event listener
      socialLinksToggle.addEventListener('change', (e) => {
        socialLinksFields.classList.toggle('hidden', !e.target.checked);
      });
    }
  }

  initializeSocialLinksToggle() {
    const toggle = document.getElementById('socialLinksEnabled');
    const fields = document.getElementById('socialLinksFields');

    if (toggle && fields) {
      toggle.addEventListener('change', (e) => {
        fields.classList.toggle('hidden', !e.target.checked);
      });
    }
  }

  initializeDarkModeToggle() {
    const darkModeToggle = document.getElementById('darkModeEnabled');
    if (!darkModeToggle) return;

    const isDarkMode = localStorage.getItem('darkMode') === 'true';
    darkModeToggle.checked = isDarkMode;
    document.documentElement.classList.toggle('dark-theme', isDarkMode);
    this.applyDarkMode(isDarkMode);

    darkModeToggle.addEventListener('change', (e) => {
      const isDark = e.target.checked;
      document.documentElement.classList.toggle('dark-theme', isDark);
      this.applyDarkMode(isDark);
      localStorage.setItem('darkMode', isDark);
    });
  }
  applyDarkMode(isDark) {
    const body = document.body;
    const nameDisplay = document.getElementById('bnsv2NameDisplay');
    const pageDescription = document.querySelector('.page-description');


    body.style.backgroundColor = '#131313';
    if (nameDisplay) nameDisplay.style.color = '#F5F5F5';
    if (pageDescription) pageDescription.style.color = '#7d7d7d';

  }

  initializeProfilePhotoToggle() {
    const profilePhotoToggle = document.getElementById('profilePhotoEnabled');
    const nftDropdown2 = document.getElementById('nftDropdownContainer2');

    if (!profilePhotoToggle || !nftDropdown2) return;

    profilePhotoToggle.addEventListener('change', async (e) => {
      nftDropdown2.style.display = e.target.checked ? 'block' : 'none';
      if (e.target.checked) {
        await this.loadNFTs();

        const select = nftDropdown2.querySelector('.nft-select');
        select?.addEventListener('change', (e) => {
          const selectedOption = e.target.options[e.target.selectedIndex];
          const imageUrl = selectedOption.dataset.imageUrl;

          if (imageUrl) {
            const profilePhoto = document.getElementById('profilePhoto');
            if (profilePhoto) {
              profilePhoto.style.backgroundImage = `url("${imageUrl}")`;
            }
          }
        });
      }
    });
  }

  async getUserStxAddress() {
    // Simply return the address since we know authService exists
    return this.router.authService.getCurrentAddress();
  }

  async updateVisibility(matchingPage, cleanAddress) {
    const userAddress = await this.getUserStxAddress();
    if (userAddress && userAddress.toLowerCase() === cleanAddress.toLowerCase()) {
      this.setPageData(matchingPage);
      this.showEditButton(true);
    } else {
      this.showEditButton(false);
    }
  }

  getBaseStyles() {
    return `
      .edit-sidebar {
        position: fixed;
        top: 0;
        left: -350px;
        width: 350px;
        height: 100vh;
        background: white;
        box-shadow: 2px 0 8px rgba(0,0,0,0.1);
        transition: left 0.3s ease;
        z-index: 1000;
      }

      .edit-sidebar.visible {
        left: 0;
      }

      .edit-sidebar-content {
        padding: 20px;
      }

      .close-btn {
        position: absolute;
        right: 20px;
        top: 20px;
        border: none;
        font-size: 24px;
        cursor: pointer;
      }

      .form-group {
        margin-bottom: 20px;
      }

      .form-group label {
        margin-bottom: 8px;
        font-weight: 500;
        display: flex;
      }

      .form-group input,
      .form-group textarea {
        width: 100%;
        padding: 8px;
        border: 1px solid #ddd;
        border-radius: 4px;
      }

      .form-group textarea {
        min-height: 100px;
      }

      .save-btn {
        color: white;
        padding: 12px 18px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        width: 100%;
      }

      .save-btn:hover {
      padding: 12px 18px;
      border: none;
      background: #2b2a31;
      color: white;
      border-radius: 4px;
      cursor: pointer;
      font-size: 14px;
      gap: 5px;
      }

      .edit-button {
        position: fixed;
        bottom: 20px;
        right: 20px;
        color: white;
        padding: 12px 24px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        display: none;
        z-index: 999;
      }

      .edit-button:hover {
      }


.nft-collection-fields {
    border: 1px solid #ddd;
    padding: 15px;
    border-radius: 4px;
    margin-top: 10px;
}

.upload-preview {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    margin-top: 10px;
}

.upload-preview img {
    width: 100%;
    height: 100px;
    object-fit: cover;
    border-radius: 4px;
}

.nft-upload {
    width: 100%;
    padding: 10px;
    border: 2px dashed #ddd;
    border-radius: 4px;
    margin-top: 5px;
}
    `;
  }

  init() {
    // Create sidebar HTML with expanded form
    const sidebarHTML = `
    <div id="editSidebar" class="edit-sidebar">
        <div class="edit-sidebar-content">
       
        <div class="close-btn-container">
            <button class="close-btn">&times;</button>
        </div>
            <h2>Edit Page</h2>
            <form id="editPageForm">
            
            <div class="form-group">
                    <label class="status-toggle">
                        <span>Profile Photo</span>
                        <input type="checkbox" id="profilePhotoEnabled" name="profilePhotoEnabled">
                        <span class="toggle-slider"></span>
                    </label>
            </div>

             <div id="nftDropdownContainer2" class="form-group nft-dropdown-container2" style="display: none;">
                <label for="nftSelect">Select NFT</label>
                <select id="profileNftSelect" class="nft-select">
                    <option value="">Select an NFT</option>
                </select>
                <div id="nftPreview2" class="nft-preview2"></div>
              </div>


                <div class="form-group">
                    <label for="pageTitle">Title</label>
                    <input type="text" id="pageTitle" name="title" required>
                </div>
                
                <div class="form-group">
                    <label for="pageDescription">Description</label>
                    <textarea id="pageDescription" name="description" required></textarea>
                </div>

                <div class="form-group">
                    <label class="status-toggle">
                        <span>Social Links</span>
                        <input type="checkbox" id="socialLinksEnabled" name="socialLinksEnabled">
                        <span class="toggle-slider"></span>
                    </label>
                </div>

                <div class="form-group">
                <div id="socialLinksFields" class="form-group social-fields hidden">
                    <div class="social-input">
                        <label for="xUrl">X</label>
                        <input type="url" id="xUrl" name="xUrl" 
                              placeholder="https://x.com/username"
                              class="social-url-input">
                    </div>
                    
                    <div class="social-input">
                        <label for="websiteUrl">Website</label>
                        <input type="url" id="websiteUrl" name="websiteUrl" 
                              placeholder="https://example.com"
                              class="social-url-input">
                    </div>
                    
                    <div class="social-input">
                        <label for="discordUrl">Discord</label>
                        <input type="url" id="discordUrl" name="discordUrl" 
                              placeholder="https://discord.com/username"
                              class="social-url-input">
                    </div>
                </div>
                </div>

          <div class="form-group content-blocks-section">
        <h3>Content Blocks</h3>
        <div class="content-blocks">
          <div class="content-block" draggable="true" data-type="heading">
            <div class="block-preview">
              <span class="block-icon">H</span>
              <span class="block-label">Heading</span>
            </div>
          </div>
          <div class="content-block" draggable="true" data-type="paragraph">
            <div class="block-preview">
              <span class="block-icon">¶</span>
              <span class="block-label">Paragraph</span>
            </div>
          </div>
        </div>
        <div id="pageContent">

          <div class="blocks-container"></div>
        </div>
      </div>

                <div class="form-group">
                    <label class="status-toggle">
                        <span>Dark Mode</span>
                        <input type="checkbox" id="darkModeEnabled" name="darkModeEnabled">
                        <span class="toggle-slider"></span>
                    </label>
                </div>
          

          <div class="form-group">
            <div id="nftCollectionFields" class="form-group nft-collection-fields hidden">
                
            <div class="collection-input">
              <label for="collectionName">Collection Name</label>
              <input type="text" id="collectionName" name="collectionName" placeholder="My NFT Collection">
            </div>

            <div class="collection-input">
                <label for="nftUpload">Upload GIFs/Images (Max 12)</label>
                <input type="file" id="nftUpload" name="nftUpload" multiple accept="image/gif,image/png,image/jpeg" class="nft-upload">
                <div id="uploadPreview" class="upload-preview grid"></div>
            </div>

            </div>  
          </div>

          <div class="form-group">
              <label class="festive-toggle">
                  <span>Festive Mode</span>
                  <input type="checkbox" id="festiveMode" name="festive">
                  <span class="toggle-slider"></span>
              </label>
          </div>

          <div id="nftDropdownContainer" class="form-group nft-dropdown-container" style="display: none;">
              <label for="nftSelect">Select NFT</label>
              <select id="nftSelect" class="nft-select">
                  <option value="">Select an NFT</option>
              </select>
              <div id="nftPreview" class="nft-preview2"></div>
          </div>

           <div class="form-group">
                    <label class="status-toggle">
                        <span>Active Status</span>
                        <input type="checkbox" id="activeStatus" name="active">
                        <span class="toggle-slider"></span>
                    </label>
                </div>

                <button type="submit" class="save-btn">Update Page</button>
            </form>
  </div>
  </div>
`;

    // Add additional styles
    const styles = `
            ${this.getBaseStyles()}
            
            /* Toggle Styles */
            .status-toggle, .festive-toggle {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                padding: 10px 0;
            }

            .toggle-slider {
                position: relative;
                display: inline-block;
                width: 50px;
                height: 24px;
                background: #ccc;
                border-radius: 12px;
                cursor: pointer;
                transition: 0.3s;
            }

            .toggle-slider:before {
                content: '';
                position: absolute;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                background: white;
                top: 2px;
                left: 2px;
                transition: 0.3s;
            }

            input[type="checkbox"] {
                display: none;
            }

            input[type="checkbox"]:checked + .toggle-slider {
                background: #4CAF50;
            }

            input[type="checkbox"]:checked + .toggle-slider:before {
                transform: translateX(26px);
            }

            /* NFT Dropdown Styles */
            .nft-dropdown-container {
                margin-top: 15px;
            }

            .nft-dropdown-container2 {
                margin-top: 15px;
            }

            .nft-collection-toggle{
              display: flex;
              flex-direction: row;
              justify-content: space-between;
            }

              .dark-mode-toggle{
              display: flex;
              flex-direction: row;
              justify-content: space-between;
            }
            .nft-select {
                width: 100%;
                padding: 8px;
                border: 1px solid #ddd;
                border-radius: 4px;
                margin-bottom: 10px;
            }

            .nft-preview2 {
              display: none;  /* Hide by default */
              align-items: center;
              gap: 10px;
                  }

                     /* Only show when it has content */
            .nft-preview2:not(:empty) {
                display: flex;
            }

            .nft-preview2 img {
                border-radius: 4px;
                object-fit: cover;
            }
        `;

    // Add styles to document
    const styleSheet = document.createElement('style');
    styleSheet.textContent = styles;
    document.head.appendChild(styleSheet);

    // Add sidebar to document
    document.body.insertAdjacentHTML('beforeend', sidebarHTML);

    // Add edit button
    const editButton = document.createElement('button');
    editButton.className = 'edit-button';
    editButton.textContent = 'Edit Page';
    document.body.appendChild(editButton);

    // Store elements
    this.sidebar = document.getElementById('editSidebar');
    this.editButton = editButton;
    this.form = document.getElementById('editPageForm');

    // Add event listeners
    this.addEventListeners();
  }

  addEventListeners() {
    // Close button
    this.sidebar.querySelector('.close-btn').addEventListener('click', () => {
      this.hide();
    });

    // Edit button
    this.editButton.addEventListener('click', () => {
      this.show();
    });

    // Festive mode toggle
    const festiveToggle = document.getElementById('festiveMode');
    const nftDropdown = document.getElementById('nftDropdownContainer');
    festiveToggle?.addEventListener('change', (e) => {
      nftDropdown.style.display = e.target.checked ? 'block' : 'none';

      if (e.target.checked) {
        this.loadNFTs();
      } else {
        // Clean up NFT fall effect when toggling off
        if (window.currentNFTFallEffect) {
          try {
            document.body.removeChild(window.currentNFTFallEffect.canvas);
            window.currentNFTFallEffect = null;
            console.log('NFT Fall Effect cleaned up successfully');
          } catch (error) {
            console.error('Error cleaning up NFT Fall Effect:', error);
          }
        }
      }
    });


    const nftCollectionToggle = document.getElementById('nftCollectionEnabled');
    const nftCollectionFields = document.getElementById('nftCollectionFields');
    const nftUpload = document.getElementById('nftUpload');
    const uploadPreview = document.getElementById('uploadPreview');

    nftCollectionToggle?.addEventListener('change', (e) => {
      nftCollectionFields.classList.toggle('hidden', !e.target.checked);
    });

    nftUpload?.addEventListener('change', async (e) => {
      uploadPreview.innerHTML = '';
      const files = Array.from(e.target.files).slice(0, 12);

      for (const file of files) {
        const formData = new FormData();
        formData.append('file', file);

        try {
          const pinataApiUrl = 'https://api.pinata.cloud/pinning/pinFileToIPFS';  // Changed endpoint
          const pinataApiKey = process.env.PINATA_API_KEY;
          const pinataSecretApiKey = process.env.PINATA_SECRET_KEY;

          const ipfsResponse = await fetch(pinataApiUrl, {
            method: 'POST',
            headers: {
              'pinata_api_key': pinataApiKey,
              'pinata_secret_api_key': pinataSecretApiKey
            },
            body: formData
          });

          const { IpfsHash } = await ipfsResponse.json();
          const ipfsUrl = `ipfs://${IpfsHash}`;

          const container = document.createElement('div');
          container.className = 'nft-preview-item';
          container.innerHTML = `
                <img src="https://ipfs.io/ipfs/${IpfsHash}" alt="NFT Preview">
                <input type="text" 
                    class="nft-name-input" 
                    data-ipfs="${ipfsUrl}"
                    placeholder="NFT Name"
                    value="${file.name.split('.')[0]}">
            `;
          uploadPreview.appendChild(container);
        } catch (error) {
          console.error('Error uploading to IPFS:', error);
        }
      }
    });

    // Form submission
    this.form.addEventListener('submit', async (e) => {
      e.preventDefault();
      const formData = new FormData(this.form);

      // Collect all content blocks
      const contentBlocks = Array.from(document.querySelectorAll('.editable-block')).map(block => ({
        type: block.dataset.type,
        content: block.querySelector('.editable').textContent,
        order: Array.from(block.parentNode.children).indexOf(block)
      }));

      try {

        // Create temporary page card with required structure
        const virtualPageCard = document.createElement('div');
        virtualPageCard.id = `page-${this.currentPageId}`;

        // Add NFT dropdown container
        const nftDropdownContainer = document.createElement('div');
        nftDropdownContainer.className = 'nft-dropdown-container';

        // Add NFT dropdown container
        const nftDropdownContainer2 = document.createElement('div');
        nftDropdownContainer2.className = 'nft-dropdown-container2';


        // Get NFT data from the actual select
        const originalSelect = document.getElementById('nftSelect');
        const festiveMode = document.getElementById('festiveMode');

        // Collect social links data
        const socialLinksEnabled = document.getElementById('socialLinksEnabled')?.checked || false;
        const socialLinksData = {
          twitter: document.getElementById('xUrl')?.value || '',
          website: document.getElementById('websiteUrl')?.value || '',
          discord: document.getElementById('discordUrl')?.value || ''
        };

        // Create page data object with all necessary information
        const pageUpdateData = {
          id: this.currentPageId,
          title: formData.get('title'),
          description: formData.get('description'),
          slug: this.router.sanitizeUrl(formData.get('title')),
          stxAddress: this.router.currentStxAddress,
          active: document.getElementById('activeStatus').checked,
          contractType: 'gated-pages',
          metadata: {
            ...this.currentPageData?.metadata,
            contentBlocks: contentBlocks,
            socialLinks: {
              enabled: socialLinksEnabled,
              links: socialLinksData
            },
            darkMode: document.documentElement.classList.contains('dark-theme')
          }
        };

        if (originalSelect && festiveMode.checked && originalSelect.selectedIndex > 0) {
          const selectedOption = originalSelect.options[originalSelect.selectedIndex];

          // Create select with the same structure as your dashboard form
          const select = document.createElement('select');
          select.className = 'nft-select';

          // Important: Keep the first empty option
          const defaultOption = document.createElement('option');
          defaultOption.value = "";
          defaultOption.textContent = "Select an NFT";
          select.appendChild(defaultOption);

          // Add the selected NFT option
          const option = document.createElement('option');
          option.value = selectedOption.value;
          option.textContent = selectedOption.textContent;
          option.dataset.imageUrl = selectedOption.dataset.imageUrl;
          option.selected = true;
          select.appendChild(option);

          // Make sure select has selectedIndex > 0 as the modal checks for this
          select.selectedIndex = 1;

          nftDropdownContainer.appendChild(select);
        }

        virtualPageCard.appendChild(nftDropdownContainer);

        // Create virtual toggle
        const virtualToggle = document.createElement('input');
        virtualToggle.type = 'checkbox';
        virtualToggle.id = `festive-gated-pages-${this.currentPageId}`;
        virtualToggle.checked = festiveMode.checked;
        virtualPageCard.appendChild(virtualToggle);

        // Add to document temporarily
        document.body.appendChild(virtualPageCard);

        // Store in router
        this.router.currentPageCard = virtualPageCard;
        this.router.currentToggle = virtualToggle;

        await this.router.showUpdateConfirmation(
          this.currentPageId,
          formData.get('title'),
          formData.get('description'),
          this.router.sanitizeUrl(formData.get('title')),
          this.router.currentStxAddress,
          document.getElementById('activeStatus').checked,
          'gated-pages',
          {
            enabled: document.getElementById('socialLinksEnabled').checked,
            links: {
              x: document.getElementById('xUrl').value,
              discord: document.getElementById('discordUrl').value,
              website: document.getElementById('websiteUrl').value
            },
            darkMode: document.documentElement.classList.contains('dark-theme')

          }
        );
        document.body.removeChild(virtualPageCard);
        this.hide();
      } catch (error) {
        console.error('Error in update flow:', error);
        alert('Failed to update page. Please try again.');
      }
    });

  }

  async updatePage(pageData) {
    try {
      const festiveToggle = document.getElementById('festiveMode');
      const profileToggle = document.getElementById('profilePhotoEnabled');
      const festiveSelect = document.getElementById('nftSelect');
      const profileSelect = document.getElementById('profileNftSelect');

      let nftData = {};
      if (festiveToggle?.checked && festiveSelect?.value) {
        const selectedOption = festiveSelect.options[festiveSelect.selectedIndex];
        nftData.festiveNFT = selectedOption.value;
        nftData.selectedNFTName = selectedOption.textContent;
        nftData.selectedNFTImage = selectedOption.dataset.imageUrl;
      }

      if (profileToggle?.checked && profileSelect?.value) {
        const selectedOption = profileSelect.options[profileSelect.selectedIndex];
        nftData.profileNFT = selectedOption.value;
        nftData.profileNFTName = selectedOption.textContent;
        nftData.profileNFTImage = selectedOption.dataset.imageUrl;
      }

      const result = await this.router.updatePage({
        id: this.currentPageId,
        title: pageData.title,
        description: pageData.description,
        active: document.getElementById('activeStatus').checked,
        contractType: 'gated-pages',
        slug: this.router.sanitizeUrl(pageData.title),
        festive: festiveToggle?.checked || false,
        ...nftData
      });

      if (result) {
        this.hide();
        if (window.currentNFTFallEffect) {
          document.body.removeChild(window.currentNFTFallEffect.canvas);
          window.currentNFTFallEffect = null;
        }
      }
    } catch (error) {
      console.error('Error updating page:', error);
      throw error;
    }
  }

  setPageData(pageData) {
    console.log('Setting page data:', pageData);
    this.currentPageId = pageData.id;
    this.currentPageData = pageData;

    // Set basic form values
    this.form.title.value = pageData.title;
    this.form.description.value = pageData.description;

    // Handle social links data
    const socialLinksEnabled = document.getElementById('socialLinksEnabled');
    const socialLinksFields = document.getElementById('socialLinksFields');

    if (socialLinksEnabled && socialLinksFields) {
      // Get social links data from the correct nested location
      const socialLinksData = pageData.metadata?.properties?.meta?.content?.socialLinks;

      console.log('Found social links data:', socialLinksData);

      if (socialLinksData) {
        // Set toggle state based on enabled property
        socialLinksEnabled.checked = socialLinksData.enabled;
        socialLinksFields.classList.toggle('hidden', !socialLinksData.enabled);

        // Set link values if they exist
        if (socialLinksData.links) {
          const links = socialLinksData.links;
          const xInput = document.getElementById('xUrl');
          const websiteInput = document.getElementById('websiteUrl');
          const discordInput = document.getElementById('discordUrl');

          console.log('Setting social link values:', links);

          if (xInput) xInput.value = links.x || '';
          if (websiteInput) websiteInput.value = links.website || '';
          if (discordInput) discordInput.value = links.discord || '';
        }
      } else {
        console.log('No social links data found');
        socialLinksEnabled.checked = false;
        socialLinksFields.classList.add('hidden');
      }
    }

    // Rest of your existing code for active status and festive mode...
    const activeToggle = document.getElementById('activeStatus');
    if (activeToggle) {
      if (pageData.value?.active?.value !== undefined) {
        activeToggle.checked = pageData.value.active.value === true;
      } else if (pageData.active?.value !== undefined) {
        activeToggle.checked = pageData.active.value === true;
      } else {
        activeToggle.checked = !!pageData.active;
      }
    }

    // Handle festive mode and NFT dropdown
    const festiveToggle = document.getElementById('festiveMode');
    const nftDropdown = document.getElementById('nftDropdownContainer');

    if (festiveToggle && pageData.metadata?.attributes) {
      const festiveMode = pageData.metadata.attributes.find(
        attr => attr.trait_type === 'Festive Mode'
      );
      festiveToggle.checked = festiveMode?.value === 'On';

      if (nftDropdown) {
        nftDropdown.style.display = festiveMode?.value === 'On' ? 'block' : 'none';
        if (festiveMode?.value === 'On') {
          this.loadNFTs();
        }
      }
    }

    const profilePhotoEnabled = document.getElementById('profilePhotoEnabled');
    if (profilePhotoEnabled) {
      const profileNFTData = pageData.metadata?.attributes?.find(
        attr => attr.trait_type === 'Profile NFT'
      );

      // Set the toggle state based on existing data
      profilePhotoEnabled.checked = !!profileNFTData?.value;

      const nftDropdown2 = document.getElementById('nftDropdownContainer2');
      if (nftDropdown2) {
        // Show/hide dropdown based on toggle state
        nftDropdown2.style.display = profilePhotoEnabled.checked ? 'block' : 'none';

        // Load NFTs only if dropdown is visible
        if (profilePhotoEnabled.checked) {
          this.loadNFTs();
        }
      }
    }
  }

  show() {
    this.sidebar.classList.add('visible');
  }

  hide() {
    this.sidebar.classList.remove('visible');
  }

  showEditButton(show) {
    this.editButton.style.display = show ? 'block' : 'none';
  }

  cleanup() {
    // Remove event listeners
    this.sidebar.querySelector('.close-btn').removeEventListener('click', this.hide);
    this.editButton.removeEventListener('click', this.show);

    // Remove elements
    this.sidebar.remove();
    this.editButton.remove();
  }

  async loadNFTs() {
    const nftDropdown = document.getElementById('nftDropdownContainer');
    const nftDropdown2 = document.getElementById('nftDropdownContainer2');

    const festiveNFTData = this.currentPageData?.metadata?.attributes?.find(
      attr => attr.trait_type === 'Festive NFT'
    );

    const profileNFTData = this.currentPageData?.metadata?.attributes?.find(
      attr => attr.trait_type === 'Profile NFT'
    );

    await Promise.all([
      this.populateNFTOptions(nftDropdown, festiveNFTData),
      this.populateNFTOptions(nftDropdown2, profileNFTData)
    ]);
  }

  checkImageLoads(url) {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => resolve(true);
      img.onerror = () => resolve(false);
      img.src = url;
    });
  }

  async populateNFTOptions(dropdownContainer, festiveNFTData) {
    const select = dropdownContainer.querySelector('.nft-select');
    if (!select) return;
    console.log("Step 6: Populating dropdown with NFTs");

    // Hide the select and preview initially
    select.style.display = 'none';
    const loadingMessage = document.createElement('div');
    loadingMessage.textContent = 'Loading NFTs...';
    dropdownContainer.insertBefore(loadingMessage, select);

    try {
      const userAddress = await this.getUserStxAddress();
      console.log('Got user address:', userAddress);

      const nftData = await this.fetchNFTs(userAddress);
      console.log("Step 6.2: NFTs Found:", nftData);

      if (!nftData || !nftData.results) {
        throw new Error('No NFT data available');
      }

      // Clear existing options except the first (default) option
      const defaultOption = select.querySelector('option[value=""]');
      const currentOption = select.querySelector('option[selected]');
      select.innerHTML = '';
      if (defaultOption) select.appendChild(defaultOption);
      if (currentOption) select.appendChild(currentOption);

      // Create or find preview div
      const previewDiv = dropdownContainer.querySelector('.nft-preview2') || document.createElement('div');
      previewDiv.className = 'nft-preview2';
      previewDiv.style.marginTop = '10px';
      previewDiv.style.display = 'none'; // Hide preview initially

      if (!dropdownContainer.querySelector('.nft-preview2')) {
        dropdownContainer.appendChild(previewDiv);
      }

      // Array to collect valid NFTs
      const validNFTs = [];

      // Process all NFTs first
      for (const nft of nftData.results) {
        const nftIdentifier = nft.asset_identifier;
        const nftId = nft.value.repr.replace('u', "");
        const [contractAddress, assetName] = nftIdentifier.split('::');

        try {
          const metadataUrl = `https://api.hiro.so/metadata/v1/nft/${contractAddress}/${nftId}`;
          const response = await fetch(metadataUrl);

          if (!response.ok) continue;

          const metadata = await response.json();
          const imageUrl = metadata.metadata.cached_thumbnail_image;

          if (!imageUrl || !(await this.checkImageLoads(imageUrl))) {
            continue;
          }

          validNFTs.push({
            identifier: nftIdentifier,
            id: nftId,
            name: assetName,
            imageUrl: imageUrl
          });

        } catch (error) {
          console.log(`Skipping NFT ${assetName} #${nftId} - error occurred`);
          continue;
        }
      }

      // Only proceed if we found valid NFTs
      if (validNFTs.length > 0) {
        // Add all valid NFTs to select
        validNFTs.forEach(nft => {
          const option = document.createElement('option');
          option.value = nft.identifier;
          option.textContent = `${nft.name} #${nft.id}`;
          option.dataset.imageUrl = nft.imageUrl;
          option.dataset.nftId = nft.id;

          if (nft.identifier === festiveNFTData?.identifier) {
            option.selected = true;
          }

          select.appendChild(option);
        });

        // Remove loading message and show select
        loadingMessage.remove();
        select.style.display = 'block';

        // Setup change listener
        select.addEventListener('change', (e) => {
          const handleNFTChange = async () => {
            const selectedOption = e.target.options[e.target.selectedIndex];
            const imageUrl = selectedOption.dataset.imageUrl;

            if (imageUrl) {
              previewDiv.innerHTML = `
        <img src="${imageUrl}" 
            alt="NFT Preview" 
            style="max-width: 100px; height: auto; border-radius: 4px;">
      `;
              previewDiv.style.display = 'block';

              // Only handle fall effect for festive mode dropdown
              if (dropdownContainer.id === 'nftDropdownContainer') {
                if (window.currentNFTFallEffect) {
                  const container = document.querySelector('.profile-section');
                  if (container && container.contains(window.currentNFTFallEffect.canvas)) {
                    container.removeChild(window.currentNFTFallEffect.canvas);
                  }
                  window.currentNFTFallEffect = null;
                }

                const container = document.querySelector('.profile-section');
                if (container) {
                  window.currentNFTFallEffect = initNFTFallEffect(imageUrl);
                }
              }
            }
          };

          handleNFTChange().catch(console.error);
        });
      } else {
        loadingMessage.textContent = 'No valid NFTs found';
      }

    } catch (error) {
      console.error('Error populating NFT options:', error);
      loadingMessage.textContent = 'Error loading NFTs';
      throw error;
    }
  }

  async fetchNFTs(userAddress) {
    console.log('Fetching NFTs for address:', userAddress);
    const nftHoldingsUrl = `https://stacks-node-api.mainnet.stacks.co/extended/v1/tokens/nft/holdings?principal=${userAddress}&limit=50`;
    console.log('Fetching from URL:', nftHoldingsUrl);

    try {
      const response = await fetch(nftHoldingsUrl);
      console.log('NFT API response status:', response.status);

      if (!response.ok) {
        throw new Error(`Failed to fetch NFT holdings: ${response.status}`);
      }

      const data = await response.json();
      console.log('NFT API response data:', data);
      return data;
    } catch (error) {
      console.error('Error fetching NFTs:', error);
      throw error;
    }
  }

  // Update the existing updatePage method to handle NFT data
  async updatePage(pageData) {
    try {
      const festiveToggle = document.getElementById('festiveMode');
      const nftSelect = document.getElementById('nftSelect');

      let nftData = {};
      if (festiveToggle && festiveToggle.checked && nftSelect && nftSelect.value) {
        const selectedOption = nftSelect.options[nftSelect.selectedIndex];
        nftData = {
          festiveNFT: selectedOption.value,
          selectedNFTName: selectedOption.textContent,
          selectedNFTImage: selectedOption.dataset.imageUrl
        };
      }

      const result = await this.router.updatePage({
        id: this.currentPageId,
        title: pageData.title,
        description: pageData.description,
        active: document.getElementById('activeStatus').checked,
        contractType: 'gated-pages',
        slug: this.router.sanitizeUrl(pageData.title),
        festive: festiveToggle?.checked || false,
        ...nftData
      });

      if (result) {
        this.hide();
        // Cleanup NFT effect if exists
        if (window.currentNFTFallEffect) {
          document.body.removeChild(window.currentNFTFallEffect.canvas);
          window.currentNFTFallEffect = null;
        }
      }
    } catch (error) {
      console.error('Error updating page:', error);
      throw error;
    }
  }

}

// Content Block Manager for EditSidebar
export class ContentBlockManager {
  constructor() {
    this.blocks = [];
    this.initDragAndDrop();
  }

  // Initialize drag and drop functionality
  initDragAndDrop() {
    // Add the content blocks section to the sidebar
    const contentBlocksHTML = `
     
    `;

    // Insert the content blocks section into the sidebar
    const sidebar = document.querySelector('.edit-sidebar-content');
    const form = sidebar.querySelector('form');
    form.insertAdjacentHTML('beforeend', contentBlocksHTML);

    // Add event listeners for drag and drop
    this.setupDragAndDrop();

    // Add styles
    this.addStyles();
  }

  // Set up drag and drop event listeners
  setupDragAndDrop() {
    const draggableBlocks = document.querySelectorAll('.content-block');
    const dropArea = document.querySelector('.blocks-container');
    const sidebar = document.querySelector('.edit-sidebar-content');

    draggableBlocks.forEach(block => {
      block.addEventListener('dragstart', (e) => {
        e.dataTransfer.setData('text/plain', block.getAttribute('data-type'));
        block.classList.add('dragging');
      });

      block.addEventListener('dragend', () => {
        block.classList.remove('dragging');
      });
    });

    dropArea.addEventListener('dragover', (e) => {
      e.preventDefault();
      dropArea.classList.add('drag-over');
    });

    dropArea.addEventListener('dragleave', () => {
      dropArea.classList.remove('drag-over');
    });

    dropArea.addEventListener('drop', (e) => {
      e.preventDefault();
      dropArea.classList.remove('drag-over');
      const blockType = e.dataTransfer.getData('text/plain');
      this.createContentBlock(blockType, dropArea);
    });
  }

  // Create a new content block in the drop area
  createContentBlock(type, container) {
    const block = document.createElement('div');
    block.className = 'content-block-instance';
    block.setAttribute('data-type', type);

    if (type === 'heading') {
      block.innerHTML = `
        <div class="block-header">
          <span class="block-type">Heading</span>
          <button class="delete-block">×</button>
        </div>
        <div class="block-content">
          <input type="text" class="heading-input" placeholder="Enter heading text">
        </div>
      `;
    } else if (type === 'paragraph') {
      block.innerHTML = `
        <div class="block-header">
          <span class="block-type">Paragraph</span>
          <button class="delete-block">×</button>
        </div>
        <div class="block-content">
          <textarea class="paragraph-input" placeholder="Enter paragraph text"></textarea>
        </div>
      `;
    }

    // Add delete functionality
    const deleteBtn = block.querySelector('.delete-block');
    deleteBtn.addEventListener('click', () => {
      block.remove();
      this.updateBlocksData();
    });

    // Add input change listeners
    const input = block.querySelector('input, textarea');
    input.addEventListener('input', () => {
      this.updateBlocksData();
    });

    container.appendChild(block);
    this.updateBlocksData();
  }

  // Update the blocks data when content changes
  updateBlocksData() {
    const blocks = document.querySelectorAll('.content-block-instance');
    this.blocks = Array.from(blocks).map(block => ({
      type: block.getAttribute('data-type'),
      content: block.querySelector('input, textarea').value
    }));
  }

  // Get the current blocks data
  getBlocksData() {
    return this.blocks;
  }

  // Add styles for the content blocks
  addStyles() {
    const styles = `
      .content-blocks-section {
        margin-top: 20px;
        border-top: 1px solid #ddd;
        padding-top: 20px;
      }

      .content-blocks {
        display: flex;
        gap: 10px;
        margin-bottom: 20px;
      }

      .content-block {
        border: 2px dashed #ccc;
        padding: 10px;
        border-radius: 4px;
        cursor: move;
        background: #f9f9f9;
        width: 100px;
        text-align: center;
      }

      .content-block.dragging {
        opacity: 0.5;
      }

      .block-preview {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
      }

      .block-icon {
        font-size: 24px;
        color: #666;
      }

      .page-content-area {
        border: 2px dashed #ddd;
        min-height: 200px;
        padding: 20px;
        margin-top: 20px;
        border-radius: 4px;
      }

      .content-drop-area {
        text-align: center;
        color: #999;
        padding: 20px;
        display: none;
      }

      .blocks-container:empty + .content-drop-area {
        display: block;
      }

      .content-block-instance {
        background: white;
        border: 1px solid #ddd;
        border-radius: 4px;
        margin-bottom: 10px;
        padding: 10px;
      }

      .block-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        padding-bottom: 5px;
        border-bottom: 1px solid #eee;
      }

      .block-type {
        font-size: 12px;
        color: #666;
      }

      .delete-block {
        border: none;
        background: none;
        color: #999;
        cursor: pointer;
        font-size: 18px;
        padding: 0 5px;
      }

      .delete-block:hover {
        color: #ff4444;
      }

      .heading-input,
      .paragraph-input {
        width: 100%;
        padding: 8px;
        border: 1px solid #ddd;
        border-radius: 4px;
        margin-top: 5px;
      }

      .paragraph-input {
        min-height: 100px;
        resize: vertical;
      }

      .drag-over {
        background-color: #f0f8ff;
        border-color: #66afe9;
      }
    `;

    const styleSheet = document.createElement('style');
    styleSheet.textContent = styles;
    document.head.appendChild(styleSheet);
  }
}

// InlineContentEditor class for direct page editing
export class InlineContentEditor {
  constructor(sidebarInstance) {
    this.sidebar = sidebarInstance; // Store reference to sidebar
    this.init();
    this.blockCount = 0;
    this.draggedBlock = null;
  }


  init() {
    const mainContent = document.querySelector('.page-content') || document.body;
    mainContent.classList.add('content-editable-area');

    // Create a hidden drop zone
    this.createDropZone();

    // Set up drag and drop from sidebar
    this.setupSidebarDrag();

    // Add styles
    this.addStyles();
  }

  createEditableBlock(type) {
    const blockId = `block-${this.blockCount++}`;
    const block = document.createElement('div');
    block.className = 'editable-block';
    block.dataset.type = type;
    block.id = blockId;
    block.draggable = true;

    const defaultText = type === 'heading' ? 'New Heading' : 'New paragraph';

    if (type === 'heading') {
      block.innerHTML = `
        <div class="block-controls">
          <button class="move-block">≡</button>
          <button class="delete-block">×</button>
        </div>
        <h2 class="editable" contenteditable="true" placeholder="Enter heading">${defaultText}</h2>
      `;
    } else if (type === 'paragraph') {
      block.innerHTML = `
        <div class="block-controls">
          <button class="move-block">≡</button>
          <button class="delete-block">×</button>
        </div>
        <p class="editable" contenteditable="true" placeholder="Enter text">${defaultText}</p>
      `;
    }
    const deleteBtn = block.querySelector('.delete-block');
    deleteBtn.addEventListener('click', () => {
      block.remove();
    });

    // Add event listeners for editing and controls
    this.setupDragHandlers(block);

    // Insert the block before the drop zone
    const mainContent = document.querySelector('.page-content') || document.body;
    mainContent.appendChild(block);

    // Close sidebar
    if (this.sidebar && typeof this.sidebar.hide === 'function') {
      this.sidebar.hide();
    }

    // Focus and select text
    const editable = block.querySelector('.editable');
    setTimeout(() => {
      editable.focus();
      // Create selection range
      const range = document.createRange();
      range.selectNodeContents(editable);
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
    }, 100); // Small delay to ensure DOM is ready
  }

  setupDragHandlers(block) {
    block.addEventListener('dragstart', (e) => {
      this.draggedBlock = block;
      block.classList.add('dragging');
      e.dataTransfer.effectAllowed = 'move';
      // Add a slight delay to make the drag visual more apparent
      setTimeout(() => block.classList.add('dragging-started'), 0);
    });

    block.addEventListener('dragend', () => {
      block.classList.remove('dragging', 'dragging-started');
      this.draggedBlock = null;
      this.removeDropIndicators();
    });

    block.addEventListener('dragover', (e) => {
      e.preventDefault();
      if (!this.draggedBlock || this.draggedBlock === block) return;

      const rect = block.getBoundingClientRect();
      const midpoint = rect.top + rect.height / 2;
      const position = e.clientY < midpoint ? 'before' : 'after';

      this.removeDropIndicators();
      block.classList.add(`drop-${position}`, 'drag-target');
    });

    block.addEventListener('dragleave', () => {
      block.classList.remove('drop-before', 'drop-after');
    });

    block.addEventListener('drop', (e) => {
      e.preventDefault();
      if (!this.draggedBlock || this.draggedBlock === block) return;

      const rect = block.getBoundingClientRect();
      const midpoint = rect.top + rect.height / 2;
      const position = e.clientY < midpoint ? 'before' : 'after';

      if (position === 'before') {
        block.parentNode.insertBefore(this.draggedBlock, block);
      } else {
        block.parentNode.insertBefore(this.draggedBlock, block.nextSibling);
      }

      this.removeDropIndicators();
    });
  }

  createDropZone() {
    // Create drop zone but don't add it to the DOM yet
    this.dropZone = document.createElement('div');
    this.dropZone.className = 'content-drop-zone hidden';
    this.dropZone.innerHTML = `
      <div class="drop-indicator">
        <span>Drop content block here</span>
      </div>
    `;

    // Set up drop zone event listeners
    this.dropZone.addEventListener('dragover', (e) => {
      e.preventDefault();
      this.dropZone.classList.add('drag-active');
    });

    this.dropZone.addEventListener('dragleave', () => {
      this.dropZone.classList.remove('drag-active');
    });

    this.dropZone.addEventListener('drop', (e) => {
      e.preventDefault();
      const blockType = e.dataTransfer.getData('text/plain');
      this.createEditableBlock(blockType);
      this.hideDropZone();
    });
  }

  setupSidebarDrag() {
    const blocks = document.querySelectorAll('.content-block');

    blocks.forEach(block => {
      block.addEventListener('dragstart', (e) => {
        e.dataTransfer.setData('text/plain', block.dataset.type);
        block.classList.add('dragging');
        this.showDropZone();
      });

      block.addEventListener('dragend', (e) => {
        block.classList.remove('dragging');
        // Small delay to ensure drop event processes first
        setTimeout(() => this.hideDropZone(), 50);
      });
    });
  }

  showDropZone() {
    const mainContent = document.querySelector('.page-content') || document.body;
    if (!this.dropZone.parentElement) {
      mainContent.appendChild(this.dropZone);
    }
    this.dropZone.classList.remove('hidden');
  }

  hideDropZone() {
    this.dropZone.classList.add('hidden');
  }


  addStyles() {
    const styles = `
      
    `;

    const styleSheet = document.createElement('style');
    styleSheet.textContent = styles;
    document.head.appendChild(styleSheet);
  }

  getContentData() {
    const blocks = document.querySelectorAll('.editable-block');
    return Array.from(blocks).map(block => ({
      type: block.dataset.type,
      content: block.querySelector('.editable').textContent
    }));
  }

  removeDropIndicators() {
    document.querySelectorAll('.editable-block').forEach(block => {
      block.classList.remove('drop-before', 'drop-after', 'drag-target');
    });
  }

}